import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loader from './components/Loader';
const Layout = lazy(() => import('./layouts'));
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Service = lazy(() => import('./pages/Service'));
const Services = lazy(() => import('./pages/Services'));
const Contact = lazy(() => import('./pages/Contact'));

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route Component={Layout} >
            <Route index Component={Home} />
            <Route path='about' Component={About} />
            <Route path='services' Component={Services} />
            <Route path='service/:slug' Component={Service} />
            <Route path='contact' Component={Contact} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App